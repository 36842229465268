import { motion, MotionValue } from 'framer-motion';
import { useMemo } from 'react';

import { useDateLocale } from '../../hooks/useDateLocale';
import { getWeekdays } from '../../utils/getWeekdays';

import styles from './Weekdays.module.scss';

type Props = {
  inverseX: MotionValue<number>,
  inverseY: MotionValue<number>
};

export function Weekdays({ inverseX, inverseY }: Props) {
  const locale = useDateLocale();
  const weekdays = useMemo(() => getWeekdays(locale), [locale]);

  return (
    <motion.div
      className={styles.weekdays}
      style={{
        x: inverseX,
        y: inverseY
      }}
    >
      {weekdays.map((day, i) => (
        <div key={i} className={styles.day}>
          {day}
        </div>
      ))}
    </motion.div>
  );
}
