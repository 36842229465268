import { ComponentPropsWithoutRef } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

import styles from './Search.module.scss';

export function Search(props: ComponentPropsWithoutRef<'input'>) {
  return (
    <div className={styles.wrapper}>
      <input
        {...props}
        type="text"
        className={styles.search}
      />
      <SearchIcon className={styles.icon}/>
    </div>
  );
}
