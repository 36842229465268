import { DialoogProps } from 'dialoog';
import { motion } from 'framer-motion';

import { InlineDialog } from '../dialogs/base/InlineDialog';

import styles from './Select.module.scss';

type Props = {
  title: string,
  formatter? : Function,
  options: string[][],
  setOption: (option: string) => void,
  action? : () => void
};

export function Select({ title, formatter, options, setOption, action, ...props }: Props & DialoogProps) {

  return (
    <InlineDialog title={title} {...props}>
      {options.map(([key, value]) => (
        <motion.button
          key={key}
          className={styles.option}
          whileTap={{ scale: .95 }}
          onClick={() => {
            setOption(key);
            (action && key !== 'never') && action!();            
            props.close();
          }}
        >
          {formatter? formatter(value) : value }
        </motion.button>
      ))}
    </InlineDialog>
  );
}
