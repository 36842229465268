import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '../components/Spinner';
import { Button } from '../components/forms/Button';
import { Form } from '../components/forms/Form';
import { Input } from '../components/forms/Input';
import { intlKeys } from '../localization-keys';
import { useForgotPasswordMutation } from '../services/authentication';

import styles from './ForgotPassword.module.scss';

export function ForgotPassword() {
  const { t } = useTranslation();
  const [forgotPassword] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const navigateToLogin = () => {
    navigate('/login');
  };

  const [formSent, setFormSent] = useState(false);
  const [responseReceived, setResponseReceived] = useState(false);
   
  return (
    <div>
      <Form
        className={styles.container}
        values={{
          email: '',
        }}
        onSubmit={(values) =>{
          setFormSent(true);
          forgotPassword(values).then(r => {
            setResponseReceived(true);
          });
        }
        }
      >
        <div className={styles.page}>
          <div className={styles.header}>
            <div className={styles.logo} />
          </div>
          <p className={styles.title}>{t(intlKeys.FORGOT_PWD)}</p>
          <p className={styles.description}>{t(intlKeys.FORGOT_PWD_TEXT)}</p>
          <Input type="email" required name="email" disabled={formSent} label={t(intlKeys.EMAIL)} />
          
          {formSent && !responseReceived &&
            <Spinner/>
          }
          
          {responseReceived && <p className={styles.info}>{t(intlKeys.FORGOT_PWD_CONFIRM)}</p> }

          <div className={styles.spacer} />
          {!formSent && <Button type="submit" text={t(intlKeys.FORGOT_PWD_BUTTON)} className={styles.button} />}
          {!formSent && <Button text={t(intlKeys.CANCEL)} className={styles.cancel} onClick={navigateToLogin}/>}
          {responseReceived && <Button text={t(intlKeys.LOGIN)} className={styles.button} onClick={navigateToLogin}/>}
        </div>
      </Form>
    </div>
  );
}
