import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyStarIcon } from '../../assets/icons/emptyStar.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { intlKeys } from '../../localization-keys';
import { useGetFavoritesQuery, useAddFavoritesMutation, useRemoveFavoritesMutation } from '../../services/favorites';
import { Location } from '../../types';
import { ListPreview } from '../ListPreview';

import styles from './Favorites.module.scss';

type Props = {
  location?: Location
  click?: Function
  refresh?: boolean
};

export function Favorites(props: Props) {

  const [remove] = useRemoveFavoritesMutation();
  const [refresh, setRefresh] = useState(props.refresh ?? false);

  useEffect(() => {
    if (props.refresh !== undefined && refresh !== props.refresh) {
      setRefresh(props.refresh);

    }
  }, [props.refresh, refresh]
  );

  const { data } = useGetFavoritesQuery(refresh, { refetchOnMountOrArgChange: true });

  const { t } = useTranslation();

  const renderFavs = (item: Location) => (
    <button
      className={styles.item}
      onClick={() => props.click === undefined ? onClickLocation(item) : props.click(item)}
    >
      <StarIcon className={styles.icon} onClick={(event) => { remove({ locationId: item.id }); setRefresh(!refresh); event.stopPropagation(); }} />
      {item.name}
    </button>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.lists}>
        <ListPreview
          name={t(intlKeys.FAVORITES)}
          keyBy="id"
          items={data ?? []}
          loadAll={() => data ?? []}
        >
          {renderFavs}
        </ListPreview>
      </div>
    </div>
  );
}

export enum FavoritesEventType {
  REMOVE,
  ADD
}

export function FavoriteLocation(props: Props) {

  const [add] = useAddFavoritesMutation();
  const [remove] = useRemoveFavoritesMutation();
  const [isFavorite, setFavorite] = useState(props.location?.favorite ?? false);

  function handleFavoriteEvents(event: MouseEvent, ops: FavoritesEventType, location?: Location) {
    if (location === null || location === undefined) return;

    switch (ops) {
    case FavoritesEventType.ADD:
      add({ locationId: location.id });
      setFavorite(true);
      break;
    case FavoritesEventType.REMOVE:
      remove({ locationId: location.id });
      setFavorite(false);
      break;
    default:
      break;
    }
    event.stopPropagation();
  }

  return (
    <button
      className={styles.item}
      onClick={
        () => props.click === undefined || props.location === undefined ? onClickLocation(null) : props.click(props.location)
      }
    >
      {isFavorite ? (
        <StarIcon className={styles.icon} onClick={(event) => handleFavoriteEvents(event, FavoritesEventType.REMOVE, props.location)} />
      ) : (
        <EmptyStarIcon className={styles.icon} onClick={(event) => handleFavoriteEvents(event, FavoritesEventType.ADD, props.location)} />
      )}
      {props.location?.name}
    </button>
  );

};

const onClickLocation = (item: Location | null) => {
  console.log('onClickLocation not implemented');
};
