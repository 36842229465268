import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { unauthenticated } from '../middleware/unauthenticated';
import authenticationApi from '../services/authentication';
import cardRegistrationApi from '../services/cardRegistration';
import chargeApi from '../services/charge';
import evsesApi from '../services/evses';
import favoritesApi from '../services/favorites';
import locationsApi from '../services/locations';
import reservationsApi from '../services/reservations';
import authentication from '../states/authentication';
import calendar from '../states/calendar';
import charge from '../states/charge';
import messages from '../states/messages';

export const store = configureStore({
  reducer: {
    [authenticationApi.path]: authenticationApi.reducer,
    [locationsApi.path]: locationsApi.reducer,
    [reservationsApi.path]: reservationsApi.reducer,
    [authentication.path]: authentication.reducer,
    [favoritesApi.path] : favoritesApi.reducer,
    [charge.path]: charge.reducer,
    [messages.path]: messages.reducer,
    [evsesApi.path]: evsesApi.reducer,
    [chargeApi.path]: chargeApi.reducer,
    [calendar.path]: calendar.reducer,
    [cardRegistrationApi.path]: cardRegistrationApi.reducer
  },
  middleware: (get) => get({ serializableCheck: false})
    .concat(unauthenticated)
    .concat(evsesApi.middleware)
    .concat(locationsApi.middleware)
    .concat(reservationsApi.middleware)
    .concat(authenticationApi.middleware)
    .concat(chargeApi.middleware)   
    .concat(favoritesApi.middleware)
    .concat(cardRegistrationApi.middleware)
});

setupListeners(store.dispatch);
