import { useDialoog } from 'dialoog';
import { Fragment, ReactNode } from 'react';

import styles from './ListPreview.module.scss';
import { InlineDialog } from './dialogs/base/InlineDialog';

type Props<T> = {
  children?: (item: T) => ReactNode,
  name: string,
  keyBy: keyof T,
  items: T[],
  loadAll: () => T[]
};

export function ListPreview<T>({ children, name, keyBy, items, loadAll }: Props<T>) {
  const [, { open }] = useDialoog();

  return items.length ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>
          {name}
        </span>
        <button
          className={styles.link}
          onClick={open.c((props) => (
            <InlineDialog title={name} {...props}>
              {loadAll().map((item) => (
                <Fragment key={item[keyBy] as any}>
                  {children?.(item)}
                </Fragment>
              ))}
            </InlineDialog>
          ))}
        >
          see all
        </button>
      </div>
      <div className={styles.list}>
        {items.map((item) => (
          <Fragment key={item[keyBy] as any}>
            {children?.(item)}
          </Fragment>
        ))}
      </div>
    </div>
  ) : null;
}
