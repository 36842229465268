import { motion } from 'framer-motion';

import styles from './Loading.module.scss';

type Props = {
  text: string,
}

export function Loading({text}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <motion.div
          className={styles.spinner}
          animate={{
            rotate: 360
          }}
          transition={{
            ease: 'linear',
            duration: .75,
            repeat: Infinity
          }}
        />
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
}