import { differenceInMinutes } from 'date-fns';
import { DialoogProps } from 'dialoog';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { useInterval } from '../../hooks/useInterval';
import { intlKeys } from '../../localization-keys';
import { useCreateRegistrationMutation, useGetRegistrationQuery } from '../../services/cardRegistration';
import { RegistrationStatus, Reservation, TokenRegistrationMessagesEnum, timeoutSeconds } from '../../types';
import { cs } from '../../utils/cs';
import { BlinkingBulb } from '../BlinkingBulb';
import { EmptyList } from '../EmptyList';
import { Spinner } from '../Spinner';
import { ChargingPill } from '../charging/ChargingPill';
import { Button } from '../forms/Button';
import { Form } from '../forms/Form';
import { ReservationHeader } from '../reservation/ReservationHeader';

import styles from './RegistrationReservationDetails.module.scss';
import { Dialog } from './base/Dialog';

type Props = {
  reservation: Reservation
};

enum RegistrationState {
  Init = 1,
  Pending = 2,
  Polling = 3,
  Result = 4
}

export function RegistrationReservationDetails({ reservation, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const { close } = props;
  const difference = useMemo(() => differenceInMinutes(reservation.startTime, new Date()), [reservation]);
  const [formEnabled, setFormEnabled] = useState(true);
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [registrationExplanation, setRegistrationExplanation] = useState('');
  const [registrationState, setRegistrationState] = useState<RegistrationState>(RegistrationState.Init);
  const [createRegistration] = useCreateRegistrationMutation();

  const shouldSkipPolling = (): boolean => {
    let state = registrationState as RegistrationState;
    return [RegistrationState.Result, RegistrationState.Pending].includes(state);
  };

  const { data, error, refetch } = useGetRegistrationQuery(reservation.id,
    { skip: shouldSkipPolling(), pollingInterval: 2500 });

  const dateStart = new Date().getTime();

  // when getRegistration data changes
  useEffect(() => {
    if (!data) {
      if(error && registrationState !== RegistrationState.Polling) {
        setRegistrationState(RegistrationState.Pending);
      }
      return;
    }
    let registration = data;

    switch (registration.status) {
    case RegistrationStatus.Pending:
      setRegistrationState(RegistrationState.Polling);
      break;

    case RegistrationStatus.Accepted:
    case RegistrationStatus.Rejected:
      setRegistrationState(RegistrationState.Result);
      let key = registration.status === RegistrationStatus.Accepted ? intlKeys.REGISTER_CARD_ACCEPTED : intlKeys.REGISTER_CARD_REJECTED;
      setRegistrationStatus(t(key));

      let errorCode = registration.error_code === TokenRegistrationMessagesEnum.DUPLICATE_TOKEN_MESSAGE ? intlKeys.DUPLICATE_TOKEN_MESSAGE : '';
      setRegistrationExplanation(t(errorCode));
      break;
    }

  }, [data, error, registrationState, t]);

  // check if timeout happened
  useInterval(() => {
    let timeNow = new Date().getTime();
    if (timeNow - dateStart > 1000 * timeoutSeconds) {
      close();
    }
  }, 1000);

  return (
    <Dialog title={t(intlKeys.CARD_REGISTRATION)} {...props}>
      {(registrationState as RegistrationState) === RegistrationState.Init &&
        <div className={styles.centerVertically}>
          <Spinner />
        </div>
      }
      {(registrationState as RegistrationState) === RegistrationState.Polling &&
        <div className={styles.centerVertically}>
          <EmptyList icon={<BlinkingBulb />}>
            <p className={styles.chargerId}>{reservation.chargerId}</p>
            <br />
            {t(intlKeys.REGISTER_CARD_POLLING)}
          </EmptyList>
          <Spinner />
        </div>
      }
      {(registrationState as RegistrationState) === RegistrationState.Result &&
        <div className={styles.centerVertically}>
          <EmptyList icon={<CardIcon/>} className={styles.list}>
            <p className={styles.chargerId}>{registrationStatus}</p>
            <p>{registrationExplanation}</p>
          </EmptyList>
          <div className={styles.spacer} />
          <Button
            text={t(intlKeys.RETURN)}
            outlined={false}
            className={styles.button}
            onClick={close}
          />
        </div>
      }
      {(registrationState as RegistrationState) === RegistrationState.Pending &&
        <>
          <ReservationHeader location={reservation.locationSummaryDto} />
          <Form
            onSubmit={() => {
              if (!formEnabled) {
                console.log('Form submited again before receiving response.');
                return;
              }
              setFormEnabled(false);
              createRegistration(reservation.id).then(() => { console.log('after craete');
                setRegistrationState(RegistrationState.Polling);
                setFormEnabled(true);
                refetch();
              });
            }}
          >
            <div className={styles.wrapper}>
              <ChargingPill
                status={t(intlKeys.IN_MINUTES).replace('%1', String(difference))}
              />
              <EmptyList icon={<BlinkingBulb />}>
                {t(intlKeys.LOOK_FOR_LED)}
                <br />
                <br />
                <p className={styles.chargerId}>{reservation.chargerId}</p>
                <br />
              </EmptyList>
              <div>
                <p>{t(intlKeys.REGISTER_CARD_BEGIN)}</p>

                <div className={styles.select}>
                  <Button
                    type="submit"
                    text={t(intlKeys.REGISTER_CARD)}
                    outlined={false}
                    className={cs(styles.startButton)}
                  />
                </div>
              </div>
            </div>
          </Form>
        </>}
    </Dialog>
  );
}
