import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { Location } from '../../types';

import styles from './LocationInfo.module.scss';

type Props = {
  location: Location
};

export function LocationInfo({ location }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <span>{location.address.street.substring(0,55)}</span>
        {location.favorite && (
          <div className={styles.favorite}>
            <StarIcon/>
            <span>Favorite Location</span>
          </div>
        )}
      </div>
      <img src={location.s3Photo ?? 'https://via.placeholder.com/150'} className={styles.image} alt=""/>
    </div>
  );
}
