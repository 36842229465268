import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { USER_TOKEN } from '../constants';

export const unauthenticated: Middleware = () => (next) => (action) => {
  
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    localStorage.removeItem(USER_TOKEN);
    if (action.payload  && action.payload.data && action.payload.data.status_message) { //only on login the status message is not empty
      return ;
    }
    window.location.href = '/login';
  }

  return next(action);
};
