import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { cs } from '../utils/cs';

import styles from './Header.module.scss';

type Props = {
  children?: ReactNode,
  text: string,
  actions?: {
    condition: boolean
    icon: ReactNode,
    onClick: string | (() => void)
  }[],
  className?: string
};

export function Header({ children, text, actions = [], className }: Props) {
  return (
    <>
      <div
        className={cs(className, styles.header, {
          [styles.shadow]: !children
        })}
      >
        {text}
        <div className={styles.spacer}/>
        {actions.map((action, i) => typeof action.onClick === 'string' ?  (
          <Link key={i} to={action.onClick} className={action.condition ? styles.action : styles.actionInvisible}>
            {action.icon}
          </Link>
        ) :  (
          <button key={i} className={action.condition ? styles.action : styles.actionInvisible } onClick={action.onClick}>
            {action.icon}
          </button>
        ))}
      </div>
      {children}
    </>
  );
}
