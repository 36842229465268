import { useState } from 'react';

import { useTimeout } from './useTimeout';

export function useDebounce<T>(value: T, delay: number) {
  const [debounced, setDebounced] = useState(value);

  useTimeout(delay, () => setDebounced(value), [value]);

  return debounced;
}
