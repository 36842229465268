import { DialoogProps } from 'dialoog';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { transition } from '../../../constants';
import { cs } from '../../../utils/cs';

import styles from './Popup.module.scss';

type Props = {
  children?: ReactNode,
  backdrop?: boolean,
  className?: string
};

export function Popup({ backdrop, children, className, open, remove }: Props & DialoogProps) {
  return (
    <>
      {backdrop && (
        <motion.div
          className={styles.backdrop}
          variants={{
            open: {
              opacity: .75
            },
            closed: {
              opacity: 0
            }
          }}
          transition={transition}
          initial="closed"
          animate={open ? 'open' : 'closed'}
        />
      )}
      <motion.div
        className={cs(className, styles.popup)}
        variants={{
          open: {
            opacity: 1,
            y: 0,
            pointerEvents: 'auto'
          },
          closed: {
            opacity: 0,
            y: '5rem',
            pointerEvents: 'none'
          }
        }}
        transition={transition}
        initial="closed"
        animate={open ? 'open' : 'closed'}
        onAnimationComplete={(animation) => animation === 'closed' && remove()}
      >
        {children}
      </motion.div>
    </>
  );
}

