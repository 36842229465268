import { DialoogProps, useDialoog } from 'dialoog';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { intlKeys } from '../../localization-keys';
import { useGetTokensQuery, useUpdateTokenMutation } from '../../services/cardRegistration';
import { cs } from '../../utils/cs';
import { Spinner } from '../Spinner';

import { RegisterCardPrompt } from './RegisterCardPrompt';
import styles from './TokenManagement.module.scss';
import { InlineDialog } from './base/InlineDialog';

export function TokenManagement(props: DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const [updateToken, { isLoading: isUpdating }] = useUpdateTokenMutation();
  const { data, isLoading, isFetching } = useGetTokensQuery();

  return (
    <InlineDialog backdrop={true} className={styles.prompt} {...props}>
      <div className={styles.header}>
        <button onClick={props.close}>
          <BackIcon className={styles.back}/>
        </button>
        {t(intlKeys.MANAGE_TOKENS)}
        <button
          className={styles.action}
          onClick={open.c((props) => (
            <RegisterCardPrompt {...props} />
          ))}
        >
          +
        </button>
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Spinner/>
        ) : data?.map((token) => (
          <motion.button
            key={token.uid}
            whileTap={{ scale: .95 }}
            disabled={isUpdating || isFetching}
            className={styles.card}
            onClick={() => updateToken({
              uid: token.uid,
              valid: !token.valid
            })}
          >
            <div
              className={cs(styles.inner, {
                [styles.invalid]: !token.valid
              })}
            >
              <CardIcon/>
              {token.uid}
              <div className={styles.spacer}/>
              {token.valid ? t(intlKeys.MANAGE_TOKENS_DISABLE) : t(intlKeys.MANAGE_TOKENS_ENABLE)}
            </div>
          </motion.button>
        )) ?? (
          <div>
            {t(intlKeys.MANAGE_TOKENS_NO_TOKENS)}
          </div>
        )}
      </div>
    </InlineDialog>
  );
}
