import styles from './ChargingBackdrop.module.scss';
import { ChargingPill } from './ChargingPill';
import { ChargingProgress } from './ChargingProgress';

type Props = {
  socket: string,
  status: string,
  energy: number,
  maximumEnergy: number
};

export function ChargingBackdrop({ socket, status, energy, maximumEnergy }: Props) {

  return (
    <div className={styles.wrapper}>
      <ChargingProgress 
        energy={energy}
        maximumEnergy={maximumEnergy}
      />
      <div className={styles.header}>
        {socket}
        <ChargingPill status={status} className={styles.pill}/>
      </div>
    </div>
  );
}
