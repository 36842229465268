import { useDialoog } from 'dialoog';
import moment from 'moment';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NO_OF_RECURRING_RESERVATIONS, RECURRENCE } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { RecurrenceType } from '../../types';
import { cs } from '../../utils/cs';
import { Select } from '../forms/Select';

import styles from './RecurrenceOption.module.scss';

type Props = {
  reference: Date,
  endDate: Date | undefined
};

export function RecurrenceOption ({reference, endDate}: Props) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const { setValue, getValues } = useFormContext();
  const [recurrenceDate, ] = useState(reference);
  const [recurrenceEndDate, setRecurrenceDateEnd] = useState(endDate);
  const recurrence = getValues(RECURRENCE);
  
  function getMonthFromString(mon:string){

    var d = Date.parse(mon + '1, 2012');
    if(!isNaN(d)){
      return new Date(d).getMonth() ;
    }
    return -1;
  }

  function parseADate(key: string)
  {

    var value = options.find((k) => k[0]=== key);
    if(value!== undefined && value[1]!==undefined){

      var year = parseInt(value[1].split('(')[0].split(' ')[3]);
      var month = getMonthFromString(value[1].split('(')[0].split(' ')[1]);
      var day = parseInt(value[1].split('(')[0].split(' ')[2]);
      return new Date(year,month,day );
    }
    else
      return new Date();
  }

  const options: string[][] =[[]];

  if(recurrence === RecurrenceType.EVERY_WEEK ){
    for(let i=1 ; i <= 12; i++){
      let newOption = [];
      var nextWeek = moment(recurrenceDate); // moment(...) can also be used to parse dates in string format
      nextWeek.add(i, 'week');       
      newOption.push(`${i}`);
      newOption.push(`${`${nextWeek.format('dddd, MMM D YYYY')} (${t(intlKeys.FOR)} ${i+1} ${t(intlKeys.WEEKS)})`}`);
      options.push(newOption);
    }
  }

  if(recurrence === RecurrenceType.EVERY_MONTH){
    for(let i= 1; i <= 12; i++){
      let newOption = [];
      var nextMonth = moment(recurrenceDate); // moment(...) can also be used to parse dates in string format
      nextMonth.add(i, 'month');          
      newOption.push(`${i}`);
      newOption.push(`${`${nextMonth.format('dddd, MMM D YYYY')} (${t(intlKeys.FOR)} ${i+1} ${t(intlKeys.MONTHS)})`}`);
      options.push(newOption);
    }
  }

  return(
    <div className={styles.recurrenceContainer}>
      <p>{t(intlKeys.END_REPEAT)}</p>
      <div className={styles.endRecurrence}>
        <p 
          className= {cs(styles.toggle, {
            [styles.error]: (recurrenceEndDate === reference )
          })}
          onClick={
            open.c((props) => (
              <Select
                title={t(intlKeys.RECURRENCE_END_DATE)}
                options={options}
                setOption={(key) => { setValue(NO_OF_RECURRING_RESERVATIONS, parseInt(key) + 1 ); 
                  setRecurrenceDateEnd( parseADate(key));                  
                }}
                {...props}
              />
            ))
          }  
        >{ recurrence !== RecurrenceType.DOES_NOT_REPEAT && recurrenceEndDate?.toDateString()}</p>
      </div>
    </div>
  );
}

