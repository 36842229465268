import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';
import { AfterChargeDetails, ChargeDetails, StartSession } from '../types';

const charge = createApi({
  reducerPath: 'chargeApi',
  baseQuery,
  endpoints: ({ mutation, query }) => ({
    startCharge: mutation<string, StartSession>({
      query: ({evseId, reference}) => ({
        url: `charge/evses/${evseId}/start`,
        method: 'post',
        body: {
          evseId: evseId,
          reference: reference
        }
      })
    }),
    stopCharge: mutation<string, {
      id: string,
      reference: string
    }>({
      query: ({id, reference}) => ({
        url: `charge/evses/${id}/stop/${reference}`,
        method: 'post',
        body: {
          evseId: id,
          reference: reference
        }
      })
    }),
    getChargeDetails: query<ChargeDetails, {
      evse: string | null,
      reference: string | null
    }>({
      query: ({ evse, reference }) => ({
        url: `charge/evses/${evse}/details/${reference}`,
        method: 'get'
      })
    }),
    getChargeCompletedDetails: query<AfterChargeDetails, {
      evse: string | null,
      reference: string | null
    }>({
      query: ({ evse, reference }) => ({
        url: `charge/evses/${evse}/details/${reference}/completed`,
        method: 'get'
      })
    })
  })
});

export const {
  useStartChargeMutation,
  useStopChargeMutation,
  useGetChargeDetailsQuery,
  useGetChargeCompletedDetailsQuery
} = charge;

export default {
  path: charge.reducerPath,
  reducer: charge.reducer,
  middleware: charge.middleware
};