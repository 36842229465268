import 'normalize.css';
import { Dialoog, DialoogProvider } from 'dialoog';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Root } from './pages/Root';
import { registerWorker } from './utils/registerWorker';
import { store } from './utils/store';

import './index.scss';
import './i18n';

render((
  <CookiesProvider>
    <Provider store={store}>
      <DialoogProvider>
        <BrowserRouter>
          <Root/>
          <Dialoog/>
        </BrowserRouter>
      </DialoogProvider>
    </Provider>
  </CookiesProvider>
), document.getElementById('root'));

registerWorker(console.log);
