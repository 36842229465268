import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowUp.svg';
import { allPurposeSingleTon } from '../../constants';
import { transition } from '../../constants';
import { useDispatch } from '../../hooks/useDispatch';
import { useInterval } from '../../hooks/useInterval';
import { useSelector } from '../../hooks/useSelector';
import { intlKeys } from '../../localization-keys';
import { useGetChargeDetailsQuery, useStopChargeMutation } from '../../services/charge';
import { selectCharging, selectCollapsed, setCollapsed, toggleCharging, toggleChargingFinished, setChargingScreen, setStoppedReservation, 
  setStartedReservation } from '../../states/charge';
import { setContent, setMessage } from '../../states/messages';
import { Reservation, SessionStatus, timeoutSeconds } from '../../types';
import {formatEVSEId} from '../../utils/formatters';
import { hasOwnProperty } from '../../utils/hasOwnPropery';
import { Loading } from '../Loading';

import styles from './Charging.module.scss';
import { ChargingBackdrop } from './ChargingBackdrop';
import { ChargingDetails } from './ChargingDetails';

const size = 192;
const preview = 64;

type Props = {
  reservation: Reservation,
  setConnectedTime: (value: string) => void
};

export function Charging({ reservation, setConnectedTime }: Props) {
  const { t } = useTranslation();
  const animation = useAnimation();
  const y = useMotionValue(192);
  const opacity = useTransform(y, (value) => value / size);
  const inverseOpacity = useTransform(opacity, (value) => 1 - value);
  const pointerEvents = useTransform(opacity, (value) => value > .5 ? 'none' : 'auto');
  const dispatch = useDispatch();
  const charging = useSelector(selectCharging);
  const collapsed = useSelector(selectCollapsed);
  const [dateStart, setDateStart] = useState<number>(new Date().getTime());
  const [stopCharging] = useStopChargeMutation();
  let [chargingLoading, setChargingLoading] = useState(false);

  //getting the actual EVSE that is charging
  let p = allPurposeSingleTon.getDictValue(reservation.reference);
  const chargerID = p!==null? p : reservation.chargerId;
  
  const errorHandler = useCallback((message)=> {
    setChargingLoading(false);
    dispatch(setContent('Something went wrong check if connector is selected if the error persists please try again later'));
    dispatch(setMessage(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sessionCompletedHandler = useCallback((timeout) => {
    setChargingLoading(false);
    setTimeout(() => {
      dispatch(toggleCharging());
      dispatch(setCollapsed(true));
      dispatch(setChargingScreen(false));
      dispatch(setStartedReservation({} as Reservation));
      dispatch(setStoppedReservation(reservation));
      dispatch(toggleChargingFinished());
    }, timeout);
  },[]);  // eslint-disable-line react-hooks/exhaustive-deps

  const { data } = useGetChargeDetailsQuery({
    evse: chargerID ,
    reference: reservation.reference,
  }, { skip: !reservation.reference,  pollingInterval: 10000 });
  
  useEffect(() => {
    if (data?.sessionStatus === SessionStatus.Invalid) {
      errorHandler(t(intlKeys.INVALID_SESSION));
      return;
    }
    if (data?.sessionStatus === SessionStatus.Completed) {
      sessionCompletedHandler(500);
    }
  },[data?.connected, data?.sessionStatus, dispatch, errorHandler, sessionCompletedHandler, t]);
  
  //updating the time should be kept separate  otherwise will open session complete screen multiple times
  useEffect(() => {
    setConnectedTime(`${data?.connected}`);
  }, [data?.connected, setConnectedTime]);

  const updateCollapsed = (c: boolean) => {
    dispatch(setCollapsed(c));
    if (collapsed === c) {
      setTimeout(() => animation.start(c ? 'hidden' : 'visible'));
    }
    dispatch(setChargingScreen(!c));
  };

  useEffect(() => void animation.start(collapsed ? 'hidden' : 'visible'), [collapsed, animation]);
  
  const toggleStopCharging = () => {
    stopCharging({
      id: chargerID,
      reference: reservation.reference
    }).then(result=>{
      if(Object.keys(result)[0] !== 'error'){
        allPurposeSingleTon.removeDictValue(reservation.reference);
        setChargingLoading(true);
        setDateStart(new Date().getTime());   
      } else {
        errorHandler(t(intlKeys.COULD_NOT_STOP_SESSION));
      }
    });  
  };
  dispatch(setContent(t(intlKeys.CHECK_CONNECTOR)));
 
  useInterval(()=> {
    if (!chargingLoading) return;
    const newTime = new Date().getTime();
    if (newTime - dateStart > 1000 * timeoutSeconds) {
      errorHandler(t(intlKeys.COULD_NOT_STOP_SESSION));
    }
  }, 1000);
  
  return (
    <div className={styles.wrapper}>
      <motion.div
        className={styles.backdrop}
        style={{
          opacity: inverseOpacity,
          pointerEvents
        }}
      >
        <ChargingBackdrop
          socket={t(intlKeys.CONNECTOR).replace('%1', formatEVSEId(chargerID))}
          status={t(intlKeys.CONNECTED)}
          energy={data ? parseFloat(data.energy) : 0}
          maximumEnergy={data ? (hasOwnProperty(data, 'maximumEnergy') ? parseFloat(data.maximumEnergy) : 100): 0}
        />
      </motion.div>
      <motion.div
        className={styles.bar}
        initial= {false}
        animate={animation}
        transition={transition}
        variants={{
          hidden: { y: charging ? size : size + preview },
          visible: { y: 0 }
        }}
        style={{ y }}
        drag={charging ? 'y' : false}
        dragConstraints={{
          top: 0,
          bottom: size
        }}
        dragElastic={.2}
        onDragEnd={(event, { velocity }) => updateCollapsed(velocity.y > -20)}
      >
        <motion.div
          className={styles.preview}
          style={{ opacity }}
        >
          {`${data?.connected}`} {t(intlKeys.CHARGING)}
          <button className={styles.arrow} onClick={() => updateCollapsed(false)}>
            <ArrowIcon/>
          </button>
        </motion.div>
        <motion.div
          style={{
            opacity: inverseOpacity,
            pointerEvents
          }}
        >
          {(charging) &&
            <ChargingDetails
              energy={ (data?.energy ? data.energy! : '0')}
              connected={(data?.connected ? data.connected! : '0:00')}
              finish={(data?.endTime ? data.endTime! : '-')}
              reservation={reservation}
              setCollapsed={updateCollapsed}
              stopChargingSession={toggleStopCharging}
            />
          }  
        </motion.div>
      </motion.div>
      {chargingLoading && <Loading text={t(intlKeys.STOPPING_SESSION)}/>}
    </div>
  );
}
