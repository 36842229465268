import { useTranslation } from 'react-i18next';

import { ReactComponent as LightningBoltIcon } from '../../assets/icons/lightningBolt.svg';
import { intlKeys } from '../../localization-keys';

import styles from './ChargingProgress.module.scss';
import { RadialProgress } from './RadialProgress';

type Props = {
  energy: number,
  maximumEnergy: number
}

export function ChargingProgress({energy, maximumEnergy}: Props) {
  const { t } = useTranslation();
  const percentage = ((energy * 100)/maximumEnergy)/2;

  return (
    <div className={styles.wrapper}>
      <RadialProgress 
        squareSize={103}
        percentage={percentage}
        strokeWidth={3.25}
      />
      <LightningBoltIcon className={styles.lighting}/>
      <span>{t(intlKeys.CHARGING)}</span>
    </div>
  );
}
