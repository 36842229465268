import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { transition } from '../../constants';
import { useDimensions } from '../../hooks/useDimensions';
import { intlKeys } from '../../localization-keys';
import { Location } from '../../types';
import { getLocationHref } from '../../utils/getLocationHref';

import { LocationInfo } from './LocationInfo';
import styles from './ReservationHeader.module.scss';

type Props = {
  location: Location
};

export function ReservationHeader({ location }: Props) {
  const { t } = useTranslation();
  const animation = useAnimation();
  const [ref, rect] = useDimensions<HTMLDivElement>();

  const height = rect?.height ?? 0;

  const y = useMotionValue(0);
  const inverseY = useTransform(y, (value) => -value - document.documentElement.offsetHeight);

  return (
    <motion.div
      className={styles.header}
      style={{ y }}
      animate={animation}
      transition={transition}
      variants={{
        hidden: { y: -document.documentElement.offsetHeight },
        visible: { y: -document.documentElement.offsetHeight + height }
      }}
      initial="hidden"
      drag="y"
      dragDirectionLock={true}
      onDragEnd={(event, { velocity }) => {
        animation.start(velocity.y > 20 ? 'visible' : 'hidden');
      }}
      dragConstraints={{
        top: -document.documentElement.offsetHeight,
        bottom: -document.documentElement.offsetHeight + height
      }}
    >
      <motion.div
        className={styles.top}
        style={{
          y: inverseY
        }}
      >
        <LocationInfo location={location}/>
      </motion.div>
      <div ref={ref} className={styles.bottom}>
        <div className={styles.details}>
          <a href={getLocationHref(location)} className={styles.anchor}>
            {t(intlKeys.GET_DIRECTIONS)}
          </a>
        </div>
      </div>
      <div className={styles.pill}/>
    </motion.div>
  );
}
