import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { intlKeys } from './localization-keys';
import { State } from './types';

export const messages: Record<string, string> = {
  email: intlKeys.INVALID_EMAIL, //'Invalid e-mail',
  string: intlKeys.CANNOT_BE_EMPTY //'Cannot be empty'
};

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as State).authentication.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const transition = {
  type: 'spring',
  damping: 40,
  stiffness: 400
};

export const returnHome = () => {
  window.location.href = '/';
  return '';
};

export const RESERVATION_DEFAULT_INTERVAL = 30;
export const NOTIFICATION = 'notification';
export const RECURRENCE = 'recurrence';
export const NO_OF_RECURRING_RESERVATIONS = 'noOfRecurrentReservations';
export const FEEDBACK_URL = 'https://form.typeform.com/to/GjEeRXSB';
export const USER_TOKEN = 'token';
export const PWA_INSTALL_SHOWN = 'PWA_INSTALL_SHOWN';
export const LANG_COOKIE = 'lang-key';
export const EMPTY_STRING = '';

class AllPurposeSingleTon {
  private static _instance: AllPurposeSingleTon;
  private CALENDAR_HEIGHT = '_innerHeight';
  private _innerDict: Map<string, string> = new Map();

  public getDictValue(key: string) {
    var value = this._innerDict.get(key);
    if (value === undefined || value === null || value === EMPTY_STRING)
      return null;
    return value;
  }

  public setDictValue(key: string, value: string) {
    if (this._innerDict.has(key))
      this._innerDict.delete(key);

    this._innerDict.set(key, value);
  }
  public removeDictValue(key: string) {
    if (this._innerDict.has(key))
      this._innerDict.delete(key);
  }

  public getInnerHeightCalendarValue() {
    let val = this.getDictValue(this.CALENDAR_HEIGHT);
    if (val !== null && val !== undefined)
      return parseInt(val);
    return 0;
  }

  public setInnerHeightCalendarValue(val: number) {
    if (val !== null && val !== undefined)
      this.setDictValue(this.CALENDAR_HEIGHT, val.toString());
  }

  private constructor() {
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

export const allPurposeSingleTon = AllPurposeSingleTon.Instance;
