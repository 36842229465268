import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Reservation, State } from '../types';

const charge = createSlice({
  name: 'charge',
  initialState: {
    charging: true,
    collapsed: true,
    chargingFinished: false,
    chargingScreen: false,
    stoppedReservation: {} as Reservation,
    startedReservation: {} as Reservation
  },
  reducers: {
    toggleCharging: (state) => {
      state.charging = !state.charging;
    },
    toggleChargingFinished: (state) => {
      state.chargingFinished = !state.chargingFinished;
    },
    toggleChargingScreen: (state) => {
      state.chargingScreen = !state.chargingFinished;
    },
    setCollapsed: (state, payload: PayloadAction<boolean>) => {
      state.collapsed = payload.payload;
    },
    setChargingScreen: (state, payload: PayloadAction<boolean>) => {
      state.chargingScreen = payload.payload;
    },
    setStoppedReservation: (state, payload: PayloadAction<Reservation>) => {
      state.stoppedReservation = payload.payload;
    },
    setStartedReservation: (state, payload: PayloadAction<Reservation>) => {
      state.startedReservation = payload.payload;
    }
  }
});

export const {
  toggleCharging,
  toggleChargingScreen,
  toggleChargingFinished,
  setCollapsed,
  setChargingScreen,
  setStoppedReservation,
  setStartedReservation
} = charge.actions;

export function selectCharging(state: State) {
  return state.charge.charging;
}

export function selectChargingFinished(state: State) {
  return state.charge.chargingFinished;
}

export function selectChargingScreen(state: State) {
  return state.charge.chargingScreen;
}

export function selectCollapsed(state: State) {
  return state.charge.collapsed;
}

export function selectStoppedReservation(state: State) {
  return state.charge.stoppedReservation;
}

export function selectStartedReservation(state: State) {
  return state.charge.startedReservation;
}

export default {
  path: charge.name,
  reducer: charge.reducer
};
