import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef } from 'react';

import { cs } from '../../utils/cs';

import styles from './FloatingButton.module.scss';

type Props = {
  text: string
};

export function FloatingButton({ text, ...props }: Props & ComponentPropsWithoutRef<typeof motion.button>) {
  return (
    <motion.button
      {...props}
      className={cs(props.className, styles.button)}
      title={text}
      whileTap={{ scale: .95 }}
    >
      {props.children ?? text}
    </motion.button>
  );
}
