import { DialoogProps } from 'dialoog';
import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { cs } from '../../../utils/cs';

import styles from './InlineDialog.module.scss';
import { Popup } from './Popup';

type Props = {
  children?: ReactNode,
  title?: string,
  backdrop?: boolean,
  className?: string
};

export function InlineDialog({ children, title, backdrop, className, ...props }: Props & DialoogProps) {
  return (
    <Popup backdrop={backdrop} className={cs(className, styles.dialog)} {...props}>
      {title && (
        <div className={styles.header}>
          <button onClick={props.close}>
            <CloseIcon/>
          </button>
          <span className={styles.title}>
            {title}
          </span>
        </div>
      )}
      <div
        className={cs(styles.list, {
          [styles.spacer]: Boolean(title)
        })}
      >
        {children}
      </div>
    </Popup>
  );
}
