import { ReactNode } from 'react';

import styles from './ReservationOption.module.scss';

type Props = {
  children?: ReactNode,
  icon: ReactNode
};

export function ReservationOption({ children, icon }: Props) {
  return (
    <div className={styles.wrapper}>
      <span className={styles.icon}>{icon}</span>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}
