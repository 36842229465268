import { DialoogProps } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { PWA_INSTALL_SHOWN } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { Button } from '../forms/Button';

import styles from './InstallPrompt.module.scss';
import { InlineDialog } from './base/InlineDialog';

type Props = {
  event: BeforeInstallPromptEvent
};

export function InstallPrompt({ event, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();

  return (
    <InlineDialog backdrop={true} className={styles.prompt} {...props}>
      <div className={styles.content}>
        <Logo className={styles.logo}/>
        <div className={styles.title}>{t(intlKeys.INSTALL)}</div>
        <div>{t(intlKeys.INSTALL_APP)}</div>
        <div className={styles.spacer}/>
        <Button
          text={t(intlKeys.INSTALL)}
          onClick={() => event.prompt().then(props.close)}
        />
        <Button text={t(intlKeys.CANCEL)} className={styles.cancel} onClick={ ()=>{
          localStorage.setItem(PWA_INSTALL_SHOWN, 'true'); // not in the task but I don't want to see this popup anymore
          props.close();
        }}/>
      </div>
    </InlineDialog>
  );
}
