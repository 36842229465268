import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useDimensions<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const [rect, setRect] = useState<DOMRectReadOnly>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      setRect(entries[0].contentRect);
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return [ref, rect] as const;
}
