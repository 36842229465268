import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from '../types';

const messages = createSlice({
  name: 'messages',
  initialState: {
    message: false,
    type: '',
    content: ''
  },
  reducers: {
    toggleMessage: (state) => {
      state.message = !state.message;
    },
    setContent: (state, payload: PayloadAction<string>) => {
      state.content = payload.payload;
    },
    setMessage: (state, payload: PayloadAction<boolean>) => {
      state.message = payload.payload;
    }
  }
});

export const {
  toggleMessage,
  setContent,
  setMessage
} = messages.actions;

export function selectMessage(state: State) {
  return state.messages.message;
}

export function selectContent(state: State) {
  return state.messages.content;
}

export default {
  path: messages.name,
  reducer: messages.reducer
};
