import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';
import { Location } from '../types';

const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery,
  refetchOnReconnect: true,
  endpoints: ({ mutation, query }) => ({
    getFavorites: query<Array<Location>, boolean>({
      query: () => ({
        url: 'favorites',
        method: 'get'
      }),     
    }),
 
    addFavorites: mutation<string, {locationId: number}>({
      query: ({locationId}) => ({
        url: `favorites/${locationId}`,
        method: 'post',
        body: {
          locationId: locationId,         
        }
      }), 
    }),
    removeFavorites: mutation<Location, {locationId: number}>({
      query: ({locationId}) => ({
        url: `favorites/${locationId}`,
        method: 'delete',
        body: {
          locationId: locationId,         
        }
      }),
    }),
  }),
  
});

export const {
  useGetFavoritesQuery,
  useAddFavoritesMutation,
  useRemoveFavoritesMutation,  
} = favoritesApi;

export default {
  path: favoritesApi.reducerPath,
  reducer: favoritesApi.reducer,
  middleware: favoritesApi.middleware
};
