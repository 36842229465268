import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 
import { LANG_COOKIE } from './constants';
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';
import { getCookie } from './utils/cookieUtils';

//new translations file will be imported and added in the below dict
const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
};

let language = getCookie(LANG_COOKIE) ?? getBrowserLocales({languageCodeOnly:true})[0]; //'nl-NL'

i18n 
  .use(initReactI18next)
  .init({    
    lng: language ,      
    resources,
    debug: false,    
    interpolation: {      
      escapeValue: false,        
    } 
  }).then((err)=>console.log('something went wrong when loading', err));

export default i18n;

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    //default to en
    return 'en';
  }
  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly
      ? trimmedLocale.split(/-|_/)[0]
      : trimmedLocale;
  });
}