import { DialoogProps, useDialoog } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { intlKeys } from '../../localization-keys';
import { Button } from '../forms/Button';

import styles from './RegisterCardPrompt.module.scss';
import { SearchLocation } from './SearchLocation';
import { InlineDialog } from './base/InlineDialog';

export function RegisterCardPrompt({ ...props }: DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();

  return (
    <InlineDialog backdrop={true} className={styles.prompt} {...props}>
      <div className={styles.content}>
        <div className={styles.title}>{t(intlKeys.REGISTER_CARD)}</div>
        
        <div className={styles.question}> {t(intlKeys.REGISTER_CARD_INFO)}
        </div>
        <div className={styles.spacer}/>  
        <Button
          text={t(intlKeys.OK)}
          onClick={
            (values) => { 
              open((props) => (
                <SearchLocation {...props} registration={true}/>
              ));
              //props.close();
            }
          }
        />

        <Button className={styles.cancel}
          text={t(intlKeys.CANCEL)}
          onClick={
            (values) => {
              props.close();
            }
          }
        />
      </div>
    </InlineDialog>
  );

}
