import ReactLoading from 'react-loading';

import styles from './Spinner.module.scss';

export function Spinner() {
  return (
    <div >
      <ReactLoading type={'bars'} color={'#00c8ff'} className={styles.loading}/>
    </div>
  );
}