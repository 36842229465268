import { DialoogProps, useDialoog } from 'dialoog';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../hooks/useDebounce';
import { intlKeys } from '../../localization-keys';
import { useGetLocationsQuery } from '../../services/locations';
import { Location } from '../../types';
import { Spinner } from '../Spinner';
import { Search } from '../forms/Search';
import { FavoriteLocation, Favorites } from '../location/Favorites';

import { LocationDetails } from './LocationDetails';
import styles from './SearchLocation.module.scss';
import { Dialog } from './base/Dialog';
import { InlineDialog } from './base/InlineDialog';

type Props = {
  onUpdate?: Function,
  registration?: boolean
};

export function SearchLocation({ onUpdate, registration, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const [search, setSearch] = useState('');
  const [refresh, setRefresh] = useState(false);
  const debouncedSearch = useDebounce(search, 250);
  const { data, isLoading } = useGetLocationsQuery(debouncedSearch, {
    skip: !debouncedSearch
  });

  const editMode = onUpdate != null && onUpdate !== undefined;

  const onClickLocation = (item: Location) => {
    if (editMode) {
      onUpdate(item);
      props.close();
    }
    else {
      open((props) => (
        <LocationDetails location={item} registration={registration} {...props} />
      ));
    }
  };

  const renderItem = (item: Location) => (
    <FavoriteLocation location={item} click={onClickLocation} />
  );

  return (
    <Dialog title={editMode ? t(intlKeys.UPDATE_RESERVATION) : t(intlKeys.NEW_RESERVATION)} {...props}>
      <div className={styles.wrapper}>
        <Search
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t(intlKeys.WHERE_TO)}
        />
        <Favorites click={onClickLocation} refresh={refresh} />
        {/* <div className={styles.lists}>
          <ListPreview
            name={t(intlKeys.RECENTS)}
            keyBy="id"
            items={data?.items ?? []}
            loadAll={() => data?.items ?? []}
          >
            {renderItem}
          </ListPreview>
        </div> */}
        <InlineDialog
          title={t(intlKeys.WHERE_TO)}
          open={Boolean(search)}
          close={() => { setSearch(''); setRefresh(!refresh); }}
          remove={() => { }}
          index={0}
          className={styles.results}
        >
          {data?.items.map((location) => (
            <Fragment key={location.id}>
              {renderItem(location)}
            </Fragment>
          ))}
          {isLoading && (
            <Spinner />
          )}
        </InlineDialog>
      </div>
    </Dialog>
  );
}
