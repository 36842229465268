import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';
import { CardRegistrationRequest, CPOToken } from '../types';

const cardRegistration = createApi({
  reducerPath: 'cardRegistrationApi',
  baseQuery,
  tagTypes: ['CardRegistration'],
  endpoints: ({ mutation, query }) => ({
    createRegistration: mutation<string, number>({
      query: (reservationId) => ({
        url: 'cardRegistration',
        method: 'post',
        body: {
          reservation_id : reservationId
        }
      }),
    }),

    getRegistration: query<CardRegistrationRequest, number>({
      query: (reservationId) => ({
        url: `cardRegistration/getByReservationId?reservationId=${reservationId}`,
        method: 'get'
      }),
    }),

    getTokens: query<CPOToken[], void>({
      query: () => ({
        url: 'cardRegistration/getTokensByGroupName',
        method: 'get'
      }),
      providesTags: ['CardRegistration']
    }),

    updateToken: mutation<void, Pick<CPOToken, 'uid' | 'valid'>>({
      query: (token) => ({
        url: 'cardRegistration/putToken',
        method: 'put',
        body: token
      }),
      invalidatesTags: ['CardRegistration']
    }),
  })
});

export const {
  useCreateRegistrationMutation,
  useGetRegistrationQuery,
  useGetTokensQuery,
  useUpdateTokenMutation,
} = cardRegistration;

export default {
  path: cardRegistration.reducerPath,
  reducer: cardRegistration.reducer,
  middleware: cardRegistration.middleware
};
