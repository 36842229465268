import { eachMinuteOfInterval, endOfDay, format, getHours, getMinutes, startOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RESERVATION_DEFAULT_INTERVAL } from '../constants';
import { intlKeys } from '../localization-keys';
import { cs } from '../utils/cs';

import styles from './Timeline.module.scss';

type Props = {
  availability: {
    hour: string,
    total: number,
    available: number
  }[],
  dateDisplay: Date,
  onClick: (date: Date) => void,
};

const slotHeight = 48;

export function Timeline({ availability, dateDisplay, onClick }: Props) {
  
  const { t } = useTranslation();
  const [now] = useState(new Date());
  const [firstTime, setFirstTime] = useState(1);
  // const hours = useMemo(() => eachHourOfInterval({
  //   start: startOfDay(dateDisplay),
  //   end: endOfDay(dateDisplay)
  // }), [dateDisplay]);

  const hours = useMemo(() => eachMinuteOfInterval(
    {
      start: startOfDay(dateDisplay),
      end: endOfDay(dateDisplay)
    },
    {
      step: RESERVATION_DEFAULT_INTERVAL
    }), [dateDisplay]);
  
  const offset = useMemo(() => 2 * (getHours(now) )* slotHeight + (getMinutes(now) + 30) / 60 * slotHeight, [now]);
  useEffect(() => {
    if (!firstTime) 
      return;
    setFirstTime(0);
    const element = document.getElementById('current-time-line');
    element?.scrollIntoView({ behavior: 'smooth', block: 'center'});
    const timeline = document.getElementById('timeline');
    setTimeout(() => {
      timeline?.scrollTo({
        left: 0,
        top: offset,
        behavior: 'smooth'
      });
    }, 100);
  }, [firstTime, offset]);

  return (
    <div className={styles.timeline} id='timeline'>
      {hours.map((hour) => {
        const a = availability.find((aa) => aa.hour === format(hour, 'HH:mm'));
        const unavailable = !a?.available;

        return (
          <button
            key={hour.getTime()}
            className={cs(styles.slot, {
              [styles.unavailable]: unavailable
            })}
            disabled={unavailable}
            onClick={() => onClick(hour)}
            data-time={format(hour, 'HH:mm')}
          >
            {!unavailable && `${a?.available}/${a?.total}`}
            {unavailable && t(intlKeys.NO_CONNECTORS)}
          </button>
        );
      })}
      <div className={styles.time} style={{ top: offset }} id='current-time-line' />
    </div>
  );
}
