import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';

import { State } from '../types';

const calendar = createSlice({
  name: 'calendar',
  initialState: {
    selected: startOfDay(new Date()).getTime()
  },
  reducers: {
    setSelected: (state, payload: PayloadAction<number>) => {
      state.selected = payload.payload;
    }
  }
});

export const {
  setSelected
} = calendar.actions;

export function selectSelected(state: State) {
  return new Date(state.calendar.selected);
}

export default {
  path: calendar.name,
  reducer: calendar.reducer
};
