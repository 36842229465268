import { useState } from 'react';

import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg';
import { ReactComponent as OverlayIcon } from '../assets/icons/bulbOverlay.svg';
import { useTimeout } from '../hooks/useTimeout';
import { cs } from '../utils/cs';

import styles from './BlinkingBulb.module.scss';

export function BlinkingBulb() {
  const [visible, setVisible] = useState(true);

  useTimeout(1000, () => setVisible(!visible), [visible]);

  return (
    <div className={styles.wrapper}>
      <BulbIcon className={styles.icon}/>
      {visible && (
        <OverlayIcon className={cs(styles.icon, styles.overlay)}/>
      )}
    </div>
  );
}
