import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { cs } from '../utils/cs';

import styles from './EmptyList.module.scss';

type Props = {
  children?: ReactNode,
  icon: ReactNode
};

export function EmptyList({ children, icon, ...props }: Props & ComponentPropsWithoutRef<'div'>) {
  return (
    <div {...props} className={cs(props.className, styles.wrapper)}>
      <div className={styles.icon}>
        {icon}
      </div>
      {children}
    </div>
  );
}
