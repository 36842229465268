import { eachDayOfInterval, endOfISOWeek, format, startOfISOWeek } from 'date-fns';

export function getWeekdays(locale?: Locale) {
  const date = new Date();
  const range = eachDayOfInterval({
    start: startOfISOWeek(date),
    end: endOfISOWeek(date)
  });

  return range.map((d) => format(d, 'EEEEE', { locale }));
}
