
export function registerWorker(onUpdate: (restart: boolean) => void) {
  if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator)) {
    return;
  }
  
  window.addEventListener('load', () => {
   
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then((worker) => {
        worker.addEventListener('fetch', (event) =>  {
          console.log('fetch from installed');
        });
        worker.onupdatefound = () => {
          const nextWorker = worker.installing;

          if (nextWorker == null) {
            return;
          }

          nextWorker.onstatechange = () => {
            if (nextWorker.state === 'installed') {
              onUpdate(Boolean(navigator.serviceWorker.controller));
            }
          };
        };
      });
  });
}
