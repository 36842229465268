import { addWeeks, endOfMonth, format, isSameDay, startOfMonth, subWeeks } from 'date-fns';
import { useDialoog } from 'dialoog';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import { ReactComponent as PalmIcon } from '../assets/icons/palm.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { EmptyList } from '../components/EmptyList';
import { Header } from '../components/Header';
import { Calendar } from '../components/calendar/Calendar';
import { Charging } from '../components/charging/Charging';
import { SessionCompleted } from '../components/charging/SessionCompleted';
import { RegistrationReservationDetails } from '../components/dialogs/RegistrationReservationDetails';
import { ReservationDetails } from '../components/dialogs/ReservationDetails';
import { Settings } from '../components/dialogs/Settings';
import { Dialog } from '../components/dialogs/base/Dialog';
import { MessageDialog } from '../components/dialogs/base/MessageDialog';
import { ReservationCard } from '../components/reservation/ReservationCard';
import {allPurposeSingleTon, USER_TOKEN} from '../constants';
import { useDateLocale } from '../hooks/useDateLocale';
import { useDispatch } from '../hooks/useDispatch';
import { useSelector } from '../hooks/useSelector';
import { useTimeout } from '../hooks/useTimeout';
import { intlKeys } from '../localization-keys';
import { useGetReservationsQuery } from '../services/reservations';
import { selectToken } from '../states/authentication';
import { selectSelected, setSelected } from '../states/calendar';
import { setCollapsed, toggleChargingScreen, selectChargingFinished,
  toggleChargingFinished, selectStoppedReservation, toggleCharging, setChargingScreen, setStartedReservation, selectStartedReservation } from '../states/charge';
import { selectMessage } from '../states/messages';
import { Reservation, ReservationStatus } from '../types';
import {formatEVSEId} from '../utils/formatters';
import { getLocationHref } from '../utils/getLocationHref';

import styles from './Home.module.scss';

export function Home() {

  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const progress = useMotionValue(0);
  const pointerEvents = useTransform(progress, (value) => value > 0 ? 'auto' : 'none');
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selected = useSelector(selectSelected);
  const [visible, setVisible] = useState(selected);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [generatedUnique, setGeneratedUnique] = useState<number>(new Date().getTime());
  const [connectedTime, setConnectedTime] = useState<string>('0:00');
  const message = useSelector(selectMessage);
  const chargingFinished = useSelector(selectChargingFinished);
  const chargingReservation = useSelector(selectStartedReservation);
  const [activeReservationId , setActiveReservationId] = useState(chargingReservation.id);
  const locale = useDateLocale();

  const { data } = useGetReservationsQuery({
    page: 0,
    generated: generatedUnique,
    start: subWeeks(startOfMonth(visible), 1).getTime(),
    end: addWeeks(endOfMonth(visible), 1).getTime()
  }, { skip: !token, refetchOnMountOrArgChange: true, pollingInterval: 15000 });

  const updateConnectedTime = (value: string) => {
    setConnectedTime(value);
    console.log('update connected time',value);
  };

  useEffect(()=>{
    setActiveReservationId(chargingReservation.id);
  }, [chargingReservation]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=> {
    let height =  document.documentElement.offsetHeight;
    if(height !== undefined && height !== 0)
      allPurposeSingleTon.setInnerHeightCalendarValue(height);
  });

  useTimeout(30, () => {
    let height =  document.documentElement.offsetHeight;
    if(height !== undefined && height !== 0)
      allPurposeSingleTon.setInnerHeightCalendarValue(height);
  },[]);

  useEffect(() => {
    const newReservationData = data ? data.items : reservations;
    const anyCharging = newReservationData.filter((reservation) => reservation.status === ReservationStatus.CHARGING) ?? [];
    if (!anyCharging.length) {
      dispatch(setChargingScreen(false));
    } else {
      dispatch(setStartedReservation(anyCharging[0]));
    }
    setReservations(data ? data.items : reservations);
  }, [data, reservations,dispatch,visible]);

  const stoppedReservation = useSelector(selectStoppedReservation);
  useEffect(() => {
    if (!chargingFinished) return;
    dispatch(open.c((props) => (
      <Dialog title={<span><p>{stoppedReservation.locationSummaryDto.address.street},</p> <p> {t(intlKeys.CHARGER_ID)} { formatEVSEId(stoppedReservation.chargerId)}</p></span>}
        onClosePopup={() => {dispatch(toggleChargingFinished()); dispatch(toggleCharging());}} {...props}>
        <div className={styles.sessionCompletedWrapper}>
          <SessionCompleted reservation={stoppedReservation} onClose={() => {
            dispatch(toggleChargingFinished());
            props.close();
            dispatch(toggleCharging());
          }}/>
        </div>
      </Dialog>
    )));
  }, [chargingFinished, stoppedReservation, dispatch, open,t]);

  if (!token) {
    return (
      <Navigate to="/login"/>
    );
  }
  const startSessionAction = (reservation: Reservation) => {
    dispatch(setStartedReservation(reservation));
    setGeneratedUnique(new Date().getTime()); // used to prefetch once again the reservations
    setTimeout(()=> {
      dispatch(setCollapsed(false));
    }, 500);
  };

  const filtered = reservations.filter((reservation) => isSameDay(reservation.startTime, selected)) ?? [];

  return (
    <>
      <Header
        text = {selected.getDate() ===  new Date().getDate()? t(intlKeys.RESERVATIONS) : visible.toLocaleString('default', { month: 'long' }) }
        actions={[{
          condition : true,
          icon: <ListIcon/>,
          onClick: '/upcoming'
        }, {
          condition: allPurposeSingleTon.getDictValue(USER_TOKEN)===null,
          icon: <SettingsIcon/>,
          onClick: open.c((props) => (
            <Settings {...props}/>
          ))
        }]}
      >
        <Calendar
          reservations={reservations.map((reservation) => reservation.startTime) ?? []}
          selected={selected}
          setSelected={(date) => dispatch(setSelected(date.getTime()))}
          visible={visible}
          setVisible={setVisible}
          progress={progress}
          className={styles.calendar}
        />
      </Header>
      <motion.div
        className={styles.month}
        style={{
          opacity: progress,
          pointerEvents
        }}
      >
        {visible.toLocaleString('default', { month: 'long' })}
      </motion.div>
      <div className={styles.container}>
        {filtered.length ? filtered.map((reservation, i) => (
          <div key={i}>
            <ReservationCard
              connectedTime={connectedTime}
              reservation={reservation}
              className={styles.charging}
              onClick={() => {
                if (reservation.status === 'CHARGING') {
                  dispatch(setCollapsed(false));
                  dispatch(toggleChargingScreen());

                  return;
                }

                void open((props) => {
                  if (reservation.status === 'CHARGED' || (reservation.status === 'FAULTY' && reservation.registration)) {
                    return !reservation.registration ? (
                      <Dialog
                        title={(
                          <span>
                            <p> {reservation.locationSummaryDto.address.street}, </p>
                            <p> {t(intlKeys.CHARGER_ID)} {formatEVSEId(reservation.chargerId)}</p>
                          </span>
                        )}
                        {...props}
                      >
                        <div className={styles.sessionCompletedWrapper}>
                          <SessionCompleted reservation={reservation} onClose={props.close}/>
                        </div>
                      </Dialog>
                    ) : (
                      <RegistrationReservationDetails reservation={reservation} {...props} />
                    );
                  } else if (reservation.status === 'PENDING') {
                    return reservation.registration ? (
                      <RegistrationReservationDetails reservation={reservation} {...props} />
                    ) : (
                      <ReservationDetails reservation={reservation} startingSession={startSessionAction} {...props} />
                    );
                  }

                  return (
                    <Dialog title={`${reservation.locationSummaryDto.address.street}`} {...props}>
                      <div className={styles.willBeActivewrap}>
                        {reservation.status === ReservationStatus.TO_BE_CHARGED.toString() ? (
                          <div className={styles.willBeActive}>
                            <p className={styles.anchor}>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(getLocationHref(reservation.locationSummaryDto));
                                }}
                              >
                                {t(intlKeys.GET_DIRECTIONS)}
                              </button>
                            </p>
                            {!reservation.registration && t(intlKeys.RESERVATION_FUTURE_SUCCESS)}
                            {reservation.registration && t(intlKeys.REGISTRATION_FUTURE_SUCCESS)}
                          </div>
                        ) : (
                          <p className={styles.willBeActive}>
                            {!reservation.registration && t(intlKeys.RESERVATION_FUTURE_FAIL)}
                            {reservation.registration && t(intlKeys.REGISTRATION_FUTURE_FAIL)}
                          </p>
                        )}
                      </div>
                    </Dialog>
                  );
                });
              }}
            />
            {(reservation.id === activeReservationId) && <Charging reservation={chargingReservation} setConnectedTime={updateConnectedTime}/>}
          </div>
        )) : (
          <EmptyList icon={<PalmIcon/>}>
            {t(intlKeys.NO_RESERVATIONS_FOR)} {format(selected, 'PP', { locale })}.<br/>{t(intlKeys.TAP_ADD)}
          </EmptyList>
        )}
        {message && <MessageDialog/>}
      </div>
    </>
  );
}
