export const emi3Separator = '*';

export function formatEVSEId(evseID: string) {  

  if(evseID===null) return '<>';

  try{
    var entries = evseID.split(emi3Separator);

    if(entries.length!==4) return evseID;
    
    return evseID.substring(8);  
  }
  catch(e) {
    console.error(e);
    return '';
  }
}