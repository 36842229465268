import { DialoogProps } from 'dialoog';
import { ReactNode } from 'react';

import { ReactComponent as BackIcon } from '../../../assets/icons/arrowLeft.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import styles from './Dialog.module.scss';
import { Popup } from './Popup';

type Props = {
  children?: ReactNode,
  title: any,
  onClosePopup?: () => void
};

export function Dialog({ children, title, onClosePopup, ...props }: Props & DialoogProps) {
  return (
    <Popup className={styles.dialog} {...props}>
      <div className={styles.header}>
        <button className={styles.close} onClick={() => {
          props.close(); onClosePopup?.();
        }}>
          {props.index ? (
            <BackIcon className={styles.back}/>
          ) : (
            <CloseIcon/>
          )}
        </button>
        {title}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </Popup>
  );
}
