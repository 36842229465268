import { addMonths, addWeeks, getWeekOfMonth, isSameDay } from 'date-fns';
import {
  AnimatePresence,
  motion,
  MotionValue,
  useAnimation,
  useMotionValue,
  useTransform
} from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

import { transition, allPurposeSingleTon } from '../../constants';
import { useTimeout } from '../../hooks/useTimeout';
import { cs } from '../../utils/cs';
import { getMonth } from '../../utils/getMonth';

import styles from './Calendar.module.scss';
import { CalendarDate } from './CalendarDate';
import { Weekdays } from './Weekdays';

const daySize = 36;
const totalSize = daySize * 5;

type Props = {
  reservations?: Date[],
  selected: Date,
  setSelected: (date: Date) => void,
  visible: Date,
  setVisible: (date: Date) => void,
  progress?: MotionValue<number>,
  className?: string
};

export function Calendar(props: Props) {
  if (!allPurposeSingleTon.getInnerHeightCalendarValue()) {
    return null;
  }

  return (
    <CalendarInternal {...props} />
  );
}

function CalendarInternal({
  reservations = [],
  selected,
  setSelected,
  visible,
  setVisible,
  progress: progressWatcher,
  className
}: Props) {

  const animation = useAnimation();
  const [axis, setAxis] = useState('');
  const [direction, setDirection] = useState(0);
  const month = useMemo(() => getMonth(visible), [visible]);
  const week = useMemo(() => getWeekOfMonth(visible, { weekStartsOn: 1 }) - 1, [visible]);
  const x = useMotionValue(-window.innerWidth);
  const y = useMotionValue(-allPurposeSingleTon.getInnerHeightCalendarValue() - totalSize);
  const inverseY = useTransform(y, (value) => -value - allPurposeSingleTon.getInnerHeightCalendarValue());
  const inverseX = useTransform(x, (value) => -value);
  const progress = useTransform(y, [-allPurposeSingleTon.getInnerHeightCalendarValue() - totalSize, -allPurposeSingleTon.getInnerHeightCalendarValue()], [0, 1]);
  const monthY = useTransform(progress, [0, 1], [totalSize - daySize * week, 0]);
  const [animate, setAnimate] = useState(true);

  useEffect(() => progressWatcher && progress.onChange((value) => progressWatcher.set(value)), [progress, progressWatcher]);

  // const windowRef = useRef(window);
  const [constraints, setConstraints] = useState(getConstraints);
  useTimeout(10, () => {
    setConstraints(getConstraints);
    animation.start({
      x: -window.innerWidth,
      y: -allPurposeSingleTon.getInnerHeightCalendarValue() - totalSize
    });
  }, []);
  // useDomEvent(windowRef, 'resize', () => {
  //   console.log('resize ?');
  //   setConstraints(getConstraints);
  //   animation.start({
  //     x: -window.innerWidth,
  //     y: -allPurposeSingleTon.innerHeightCalendarValue - totalSize
  //   });
  // });

  useEffect(() => setAnimate(true), [animate]);

  return (
    <motion.div
      className={cs(className, styles.calendar)}
      style={{ x, y }}
      animate={animation}
      transition={transition}
      drag={true}
      dragDirectionLock={true}
      dragConstraints={constraints}
      dragElastic={.2}
      onDirectionLock={setAxis}
      onDragEnd={(event, { offset, velocity }) => {
        const p = progress.get();

        if (axis === 'y' || (p !== 0 && p !== 1)) {
          animation.start({
            x: -window.innerWidth,
            y: -allPurposeSingleTon.getInnerHeightCalendarValue() - (velocity.y > 20 ? 0 : totalSize)
          });
        }

        if (axis !== 'x') {
          return;
        }

        const power = Math.abs(offset.x) * velocity.x;
        const add = p > 0 ? addMonths : addWeeks;

        if (power > 10000) {
          setDirection(-1);
          setVisible(add(visible, -1));
        } else if (power < -10000) {
          setDirection(1);
          setVisible(add(visible, 1));
        }
      }}
    >
      <Weekdays
        inverseX={inverseX}
        inverseY={inverseY}
      />
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={visible.getTime()}
          className={styles.month}
          style={{
            y: monthY
          }}
          transition={animate ? transition : {
            type: false
          }}
          variants={{
            enter: (direction: number) => ({
              x: `${100 * direction}%`
            }),
            visible: {
              x: 0
            }
          }}
          initial="enter"
          animate="visible"
          custom={direction}
        >
          {month.map((date) => (
            <CalendarDate
              key={date.getTime()}
              date={date}
              visible={visible}
              selected={date.getTime() === selected.getTime()}
              reserved={reservations.some((d) => isSameDay(d, date))}
              progress={progress}
              setSelected={() => {
                setSelected(date);

                if (progress.get() > 0) {
                  setVisible(date);
                  setAnimate(false);
                }
              }}
            />
          ))}
        </motion.div>
      </AnimatePresence>
      <motion.div
        className={styles.pill}
        style={{
          x: inverseX,
          y: 1
        }}
      />
    </motion.div>
  );
}

function getConstraints() {
  return {
    left: -window.innerWidth,
    right: -window.innerWidth,
    top: -allPurposeSingleTon.getInnerHeightCalendarValue() - totalSize,
    bottom: -allPurposeSingleTon.getInnerHeightCalendarValue()
  };
}
