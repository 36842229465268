import { DialoogProps, useDialoog } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/icons/feedback.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg';
import { FEEDBACK_URL, PWA_INSTALL_SHOWN, USER_TOKEN } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { cs } from '../../utils/cs';

import styles from './Settings.module.scss';
import { TokenManagement } from './TokenManagement';
import { InlineDialog } from './base/InlineDialog';

export function Settings(props: DialoogProps) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();

  return (
    <InlineDialog className={styles.settings} {...props}>
      <div className={styles.header}>
        <button onClick={props.close}>
          <BackIcon className={styles.back}/>
        </button>
        {t(intlKeys.SETTINGS)}
      </div>

      <div className={styles.option}>
        <CardIcon className={styles.icon}/>
        <button
          className={styles.action}
          onClick={open.c((props) => (
            <TokenManagement {...props}/>
          ))}
        >
          {t(intlKeys.MANAGE_TOKENS)}
        </button>
      </div>

      <div className={styles.option}>
        <LogOutIcon className={styles.icon}/>
        <button
          className={cs(styles.action, styles.red)}
          onClick={() => {
            localStorage.removeItem(PWA_INSTALL_SHOWN);
            localStorage.removeItem(USER_TOKEN);
            window.location.href = '/login';
          }}
        >
          {t(intlKeys.LOGOUT)}
        </button>
      </div>

      <div className={styles.option}>
        <FeedbackIcon className={styles.icon}/>
        <a href={FEEDBACK_URL} className={styles.action}>
          {t(intlKeys.GIVE_FEEDBACK)}
        </a>
      </div>
    </InlineDialog>
  );
}
