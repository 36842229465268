import React, { ComponentPropsWithoutRef } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { messages } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { cs } from '../../utils/cs';

import styles from './Input.module.scss';

type Props = {
  name: string,
  label: string
};

export function Input({ name, label, ...props }: Props & ComponentPropsWithoutRef<'input'>) {
  const {t} = useTranslation();
  const { register, formState: { errors: formErrors } } = useFormContext();

  const error = get(formErrors, name);

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <input
        {...register(name)}
        className={cs(styles.input, {
          [styles.withError]: Boolean(error)
        })}
        {...props}
      />
      <span className={styles.error}>
        {error && t((messages[error.type] ?? intlKeys.UNKNOWN_ERROR))}
      </span>
    </div>
  );
}
