import { motion, useAnimation } from 'framer-motion';
import { useState, useEffect } from 'react';

import styles from './WheelPicker.module.scss';

type Props = {
  setArrivalHour: (val: string) => void,
  setArrivalMinutes:(val: string) => void,
  selectedTime: string,
}

export function WheelPicker({setArrivalHour, setArrivalMinutes, selectedTime}: Props) {

  const currentTime = selectedTime.split(':');
  const [hours, setHours] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<string[]>([]);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [hoursIndex, setHoursIndex] = useState<number>(currentTime ? parseInt(currentTime[0]) : 1);
  const [minutesIndex, setMinutesIndex] = useState<number>(currentTime ? (parseInt(currentTime[1])/30) : 1);

  const hourAnimation = useAnimation();
  const minutesAnimation = useAnimation();

  const height = 69;
  const hoursContainerBottomValue = (hours.length - 2) * height;
  const minutesContainerBottomValue = (minutes.length - 2) * height;

  let generateInitalHours = () => {
    let firstHour = 0;
    let lastHour = 23;
    let hoursArray = [];

    for(let hour = firstHour; hour <= lastHour; hour++){
      if(hour < 10) hoursArray.push(`0${hour}`);
      if(hour >= 10) hoursArray.push(`${hour}`);
    }
    return hoursArray;
  };

  useEffect(() => {
    let initialHours = generateInitalHours();

    let initialMinutes = [
      '00',
      '30'
    ];

    setMinutes(initialMinutes);

    setHours(initialHours);
  }, []);

  const snapHoursToCenter = (info: any) => {
    let offset;

    if (dragOffset === 0 && currentTime){
      offset =  height * (-(hoursIndex - 1) + (Math.round((dragOffset + info.offset.y) / height)));
      if (offset > height) offset = height;
      if (offset < -hoursContainerBottomValue) offset = -hoursContainerBottomValue;
    } else {
      if ( dragOffset + info.offset.y > height ){
        offset = height;
      } else if ( dragOffset + info.offset.y < -hoursContainerBottomValue){
        offset = -hoursContainerBottomValue;
      } else {
        offset = height * (Math.round((dragOffset + info.offset.y) / height));
      }
    }

    let indexOfValue = offset/height;
    if( indexOfValue >= 1){
      setArrivalHour(hours[0]);
      setHoursIndex(0);
    } else {
      setArrivalHour(hours[Math.abs(offset/height) + 1]);
      setHoursIndex(Math.abs(offset/height) + 1);
    }

    hourAnimation.start({y: offset});
    setDragOffset(offset);
  };

  const snapMinutesToCenter = (info: any) => {
    let offset;

    if (dragOffset === 0 && currentTime){
      offset =  height * (-(minutesIndex - 1) + (Math.round((dragOffset + info.offset.y) / height)));
      if (offset > height) offset = height;
      if (offset < minutesContainerBottomValue) offset = minutesContainerBottomValue;
    } else {
      if ( dragOffset + info.offset.y > height ){
        offset = height;
      } else if ( dragOffset + info.offset.y < minutesContainerBottomValue){
        offset = minutesContainerBottomValue;
      } else {
        offset = height * (Math.round((dragOffset + info.offset.y) / height));
      }
    }

    let indexOfValue = offset/height;
    if( indexOfValue >= 1){
      setArrivalMinutes(minutes[0]);
      setMinutesIndex(0);
    } else {
      setArrivalMinutes(minutes[Math.abs(offset/height) + 1]);
      setMinutesIndex(Math.abs(offset/height) + 1);
    }

    minutesAnimation.start({y: offset});
    setDragOffset(offset);
  };

  return (
    <div className={styles.container}>
      <div className={styles.pickedContainer}>
        <div className={styles.borderCutVertical}/>
      </div>
      <motion.ul
        animate={hourAnimation}
        className={styles.wrapperHours}
        drag="y"
        dragConstraints={{ top: -hoursContainerBottomValue, bottom: height }}
        dragTransition={{bounceDamping: 50,  bounceStiffness: 600}}
        style={{y: -((hoursIndex - 1) * height)}}
        onDragEnd={(event, info) => {snapHoursToCenter(info);}}
      >
        {hours?.map((hour, index) => (
          index === hoursIndex ? (
            <motion.li
              key={index}
              className={styles.selectedTimeWrapper}
            >
              <span>{hour}</span>
            </motion.li>
          ) : (
            <motion.li
              key={index}
              className={styles.timeWrapper}
            >
              <span>{hour}</span>
            </motion.li>
          )))}
      </motion.ul>
      <p className={styles.column}>:</p>
      <motion.ul
        animate={minutesAnimation}
        className={styles.wrapperMinutes}
        drag="y"
        dragConstraints={{ top: minutesContainerBottomValue, bottom: height }}
        onDragEnd={
          (event, info) => {snapMinutesToCenter(info);}
        }
        style={{y: -((minutesIndex - 1) * height)}}
      >
        {minutes?.map((minutes, index) => (
          index === minutesIndex ? (
            <motion.li
              key={index}
              className={styles.selectedTimeWrapper}
            >
              <span>{minutes}</span>
            </motion.li>
          ) : (
            <motion.li
              key={index}
              className={styles.timeWrapper}
            >
              <span>{minutes}</span>
            </motion.li>
          )
        ))}
      </motion.ul>
    </div>
  );
}
