import { formatDuration, intervalToDuration, isBefore, parse, format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RESERVATION_DEFAULT_INTERVAL } from '../../constants';
import { useDateLocale } from '../../hooks/useDateLocale';
import { intlKeys } from '../../localization-keys';
import { useGetMinEvseAvailableQuery } from '../../services/evses';
import { Location } from '../../types';
import { cs } from '../../utils/cs';

import styles from './TimeOption.module.scss';
import { WheelPicker } from './WheelPicker';

type Props = {
  startLabel: string,
  startName: string,
  endLabel: string,
  endName: string,
  location: Location,
  date: Date
  reservationId?: number,
  hideEnd?:boolean
};

export function TimeOption({ startLabel, startName, endLabel, endName, location, date, reservationId, hideEnd }: Props) {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext();
  const [open, setOpen] = useState<number>();
  const locale = useDateLocale();

  const [startValue, endValue] = watch([startName, endName]);

  const {data}=  useGetMinEvseAvailableQuery({
    locationId: location.id,
    startTime: `${format(date, 'yyyy-MM-dd')}T${getValues(startName)+ ':00'}.000Z`,
    endTime: `${format(date, 'yyyy-MM-dd')}T${getValues(endName)+ ':00'}.000Z`,
    excludedReservationId : reservationId ?? 0
  },  { refetchOnMountOrArgChange: true, skip: getValues(endName)<getValues(startName)});

  const difference = useMemo(() => {
    const start = parse(startValue, 'HH:mm', new Date());
    const end = parse(endValue, 'HH:mm', new Date());

    if (!isBefore(start, end)) {
      setValue('availableEVSE',0);
      return intlKeys.INVALID_TIME;
    }
    setValue('availableEVSE',data);

    return formatDuration(intervalToDuration({ start, end }), { locale });
  }, [startValue, endValue, setValue, data, locale]);

  const updateTime = (name: string, part: number) => (value: string) => {
    const current = getValues(name) as string;
    const split = current.split(':');

    split[part] = value;

    setValue(name, split.join(':'));

    if(name === startName && hideEnd) {
      const time = parseInt(split[0])*60 +parseInt(split[1]) + RESERVATION_DEFAULT_INTERVAL;
      setValue(endName, `${('0'+Math.floor(time/60)).slice(-2)}:${('0'+(time % 60)).slice(-2)}`);
    }

  };

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.toggle}
        data-value={startValue}
        onClick={() => setOpen(open === 0 ? undefined : 0)}
      >
        {startLabel}
      </button>
      {open === 0 && (
        <WheelPicker
          setArrivalHour={updateTime(startName, 0)}
          setArrivalMinutes={updateTime(startName, 1)}
          selectedTime={getValues('startTime')}
        />
      )}
      {!hideEnd && <button
        type="button"
        className={styles.toggle}
        data-value={endValue}
        onClick={() => setOpen(open === 1 ? undefined : 1)}
      >
        {endLabel}
      </button>
      }
      {open === 1 && (
        <WheelPicker
          setArrivalHour={updateTime(endName, 0)}
          setArrivalMinutes={updateTime(endName, 1)}
          selectedTime={getValues('endTime')}
        />
      )}
      <div className={styles.details}>
        <span
          className={cs(styles.toggle, {
            [styles.error]: startValue >= endValue
          })}
          data-value={t(intlKeys.AVAILABLE_CONNECTORS).replace('%1', String(data)).replace('%2', String(location.totalEvses))}
        >
          {difference === intlKeys.INVALID_TIME ? t(intlKeys.INVALID_TIME) : difference }
        </span>
      </div>
    </div>
  );
}
