import { addWeeks, eachDayOfInterval, getISODay, startOfMonth, subDays } from 'date-fns';

export function getMonth(date: Date) {
  const start = startOfMonth(date);
  const monday = subDays(start, getISODay(start) - 1);

  return eachDayOfInterval({
    start: monday,
    end: subDays(addWeeks(monday, 6), 1)
  });
}
