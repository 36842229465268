import { useTranslation } from 'react-i18next';

import { ReactComponent as FeedbackIcon } from '../../assets/icons/feedback.svg';
import { ReactComponent as Receipt } from '../../assets/icons/receipt.svg';
import { FEEDBACK_URL } from '../../constants';
import { intlKeys } from '../../localization-keys';
import { useGetChargeCompletedDetailsQuery } from '../../services/charge';
import { Reservation } from '../../types';
import { EmptyList } from '../EmptyList';
import { Button } from '../forms/Button';

import styles from './SessionCompleted.module.scss';

type Props = {
  reservation: Reservation,
  onClose: () => void
};

export function SessionCompleted({reservation, onClose }: Props) {
  const { t } = useTranslation();
  const { data } = useGetChargeCompletedDetailsQuery({
    evse: reservation.chargerId ,
    reference: reservation.reference,
  });

  return (
    <div className={styles.wrapper}>
      <EmptyList icon={<Receipt/>} className={styles.list}>
        {t(intlKeys.SESSION_COMPLETED)}
      </EmptyList>
      <div className={styles.grid}>
        <div className={styles.item}>
          {t(intlKeys.STARTED_AT)}
          <span className={styles.value}>{data?.startTime}</span>
        </div>
        <div className={styles.item}>
          {t(intlKeys.FINISHED_AT)}
          <span className={styles.value}>{data?.endTime}</span>
        </div>
        <div className={styles.item}>
          {t(intlKeys.CONNECTED)}
          <span className={styles.value}>{data?.connected}</span>
        </div>
        <div className={styles.item}>
          {t(intlKeys.ENERGY)}
          <span className={styles.value}>{data?.energy} kWh</span>
        </div>
      </div>
      <div className={styles.spacer}/>
      <Button
        text={t(intlKeys.RETURN)}
        outlined={false}
        className={styles.button}
        onClick={onClose}
      />
      <div  className={styles.feedbackOption}>
        <a href= {FEEDBACK_URL} className={styles.anchor} >             
          <FeedbackIcon className={styles.feedback} />
          {t(intlKeys.GIVE_FEEDBACK)}   
        </a>
      </div>

    </div>
  );
}
