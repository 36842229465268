import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { USER_TOKEN } from '../constants';
import { State } from '../types';
import { getCookie , setCookie} from '../utils/cookieUtils';

const authentication = createSlice({
  name: 'authentication',
  initialState: {
    token: localStorage.getItem(USER_TOKEN) ?? getCookie(USER_TOKEN) ?? undefined
  },
  reducers: {
    login: (state, payload: PayloadAction<string>) => {
      localStorage.setItem(USER_TOKEN, payload.payload);

      state.token = payload.payload;
    },
    logout: (state) => {
      localStorage.removeItem(USER_TOKEN);
      if(getCookie(USER_TOKEN)!==undefined && state.token)
      {
        setCookie(USER_TOKEN,'',-1);
      }
      state.token = undefined;
    }
  }
});

export const {
  login,
  logout
} = authentication.actions;

export function selectToken(state: State) { 
  return state.authentication.token;
}

export default {
  path: authentication.name,
  reducer: authentication.reducer
};

