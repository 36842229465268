import { motion } from 'framer-motion';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { useDispatch } from '../../../hooks/useDispatch';
import { useSelector } from '../../../hooks/useSelector';
import { selectContent, setMessage } from '../../../states/messages';

import styles from './MessageDialog.module.scss';

export function MessageDialog() {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);

  return (
    <motion.div
      initial={{opacity: 0}}
      className={styles.messageContainer}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.messageCard}>
        <motion.button 
          className={styles.close}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            dispatch(setMessage(false));
          }}
        >
          <CloseIcon/>
        </motion.button>
        <p className={styles.messageContent}>
          <span className={styles.messageIcon}> 
            <ErrorIcon/>
          </span>
          {content}
        </p>
      </div>
    </motion.div>
  );
}
