import { useDialoog } from 'dialoog';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { SearchLocation } from '../components/dialogs/SearchLocation';
import { FloatingButton } from '../components/forms/FloatingButton';
import { useSelector } from '../hooks/useSelector';
import { intlKeys } from '../localization-keys';
import { selectCharging, selectChargingScreen } from '../states/charge';
import { cs } from '../utils/cs';

import styles from './StatusWrapper.module.scss';

export function StatusWrapper() {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const charging = useSelector(selectCharging);
  const chargingScreen = useSelector(selectChargingScreen);

  return (
    <div
      className={cs(styles.wrapper, {
        [styles.charging]: charging
      })}
    >
      <Outlet/>
      <FloatingButton
        text={t(intlKeys.NEW_RESERVATION)}
        onClick={open.c((props) => (
          <SearchLocation {...props}/>
        ))}
        className={cs({
          [styles.floating]: charging,
          [styles.chargingScreen] : chargingScreen
        })}
      >
        +
      </FloatingButton>
    </div>
  );
}
