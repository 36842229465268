import { ComponentPropsWithoutRef } from 'react';

import { cs } from '../../utils/cs';

import styles from './ChargingPill.module.scss';

type Props = {
  status: string
};

export function ChargingPill({ status, ...props }: Props & ComponentPropsWithoutRef<'div'>) {
  return (
    <div {...props} className={cs(props.className, styles.status)}>
      {status}
    </div>
  );
}
