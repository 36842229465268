import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowDown.svg';
import { intlKeys } from '../../localization-keys';
import { Reservation } from '../../types';
import { Button } from '../forms/Button';

import styles from './ChargingDetails.module.scss';

type Props = {
  energy: string,
  connected: string,
  finish: string,
  reservation: Reservation,
  setCollapsed: (collapsed: boolean) => void
  stopChargingSession: () => void
};

export function ChargingDetails({ reservation, energy, connected, finish, setCollapsed, stopChargingSession }: Props) {
  const { t } = useTranslation();
  const [, finistAt] = finish.split(' '); //display HH:mm

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.details}>
          <div className={styles.detail}>
            <span className={styles.title}>{t(intlKeys.ENERGY)}</span>
            <span>{energy} kWh</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.title}>{t(intlKeys.CONNECTED)}</span>
            <span>{connected}</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.title}>{t(intlKeys.FINISH_AT)}</span>
            <span>{finistAt}</span>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            text={t(intlKeys.STOP_CHARGING)}
            outlined={true}
            className={styles.button}
            onClick={() => {stopChargingSession();}}
          />
          <button 
            className={styles.collapse} 
            onClick={() => {
              setCollapsed(true);
            }}
          >
            <ArrowIcon/>
          </button>
        </div>
      </div>
      
    </>
  );
}
