import { differenceInMinutes } from 'date-fns';
import { DialoogProps } from 'dialoog';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { allPurposeSingleTon } from '../../constants';
import { useDispatch } from '../../hooks/useDispatch';
import { useInterval } from '../../hooks/useInterval';
import { intlKeys } from '../../localization-keys';
import { useStartChargeMutation, useGetChargeDetailsQuery } from '../../services/charge';
import { toggleCharging, setChargingScreen } from '../../states/charge';
import { setContent, setMessage } from '../../states/messages';
import { Reservation, SessionStatus, timeoutSeconds } from '../../types';
import { cs } from '../../utils/cs';
import {formatEVSEId} from '../../utils/formatters';
import { BlinkingBulb } from '../BlinkingBulb';
import { EmptyList } from '../EmptyList';
import { Loading } from '../Loading';
import { ChargingPill } from '../charging/ChargingPill';
import { Button } from '../forms/Button';
import { Form } from '../forms/Form';
import { ReservationHeader } from '../reservation/ReservationHeader';
import { SelectOption } from '../reservation/SelectOption';

import styles from './ReservationDetails.module.scss';
import { Dialog } from './base/Dialog';

type Props = {
  reservation: Reservation
  startingSession: (reservation: Reservation) => void
};

export function ReservationDetails({ reservation, startingSession, ...props }: Props & DialoogProps) {
  const { t } = useTranslation();
  const difference = useMemo(() => differenceInMinutes(reservation.startTime, new Date()), [reservation]);
  const [evses, setEvses] = useState([['',''],['','']]);
  const [startCharge] = useStartChargeMutation();
  const [dateStart, setDateStart] = useState<number>(new Date().getTime());
  const dispatch = useDispatch();
  const [formEnabled, setFormEnabled] = useState(true);

  //TODO: either remove, either add more evse to choose but display some physical identifiers
  // const { data } = useGetNotChargingEvsesQuery({
  //   locationId: reservation.locationSummaryDto.id, 
  //   startTime: `${format(reservation.startTime, 'yyyy-MM-dd')}T${format(reservation.startTime, 'HH:mm:ss')}.000Z`, 
  //   endTime: `${format(reservation.endTime, 'yyyy-MM-dd')}T${format(reservation.endTime, 'HH:mm:ss')}.000Z`,
  //   excludedReservationId : reservation.id
  // });

  useEffect(()=>{
    const listOfEvses : string[][] = [];
    let newEvse = [];
    newEvse.push(reservation.chargerId);
    newEvse.push(reservation.chargerId);
    listOfEvses.push(newEvse);
    setEvses(listOfEvses);
  },[reservation.chargerId]);

  let [chargingLoading, setChargingLoading] = useState(false);
  const startErrorHandler = useCallback(() => {
    setChargingLoading(false);
    dispatch(setContent(t(intlKeys.CHECK_CONNECTOR)));
    dispatch(setMessage(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const chargingQuery = useGetChargeDetailsQuery({
    evse: reservation.chargerId ,
    reference: reservation.reference,
  }, { skip: !chargingLoading , pollingInterval: 5000 });

  const { close} =  props;
  useEffect(() => {
    if (chargingQuery.data?.sessionStatus === SessionStatus.Invalid) {
      startErrorHandler();
      return;
    }
    if (chargingQuery.data?.sessionStatus === SessionStatus.Active) {
      setChargingLoading(false);
      dispatch(toggleCharging());
      setTimeout(() => {
        close();
        startingSession(reservation);
        dispatch(setChargingScreen(true));
      }, 1000);        
    }
    // if the session was forced stopped
    if (chargingQuery.data?.sessionStatus === SessionStatus.Completed) {
      setChargingLoading(false);
      dispatch(toggleCharging());
      setTimeout(() => {
        close();
        startingSession(reservation);
        dispatch(setChargingScreen(true));
      }, 1000);        
    }
  }, [dispatch, chargingQuery.data, close, startErrorHandler,startingSession, reservation]);
  useInterval(()=> {
    if (!chargingLoading) return;
    const newTime = new Date().getTime();
    if (newTime - dateStart > 1000 * timeoutSeconds) {
      startErrorHandler();
    }
  }, 1000);

  return (
    <Dialog title= {t(intlKeys.YOUR_RESERVATION)} {...props}>
      <ReservationHeader location={reservation.locationSummaryDto}/>
      <Form
        values={{
          evse: reservation.chargerId
        }}
        onSubmit={(values) => {
          if(!formEnabled) {
            console.log('Form submited again before receiving response.');
            return;
          }

          setFormEnabled(false);
          console.log('Choose EVSE ID: ' + values.evse + '  default: ' + reservation.chargerId);
          
          startCharge({
            evseId: values.evse, 
            reference: reservation.reference
          }).then((response) => {
            setFormEnabled(true);
            if(Object.keys(response)[0] !== 'error'){
              //setting up which EVSE starts charging on behalf of which reservation
              allPurposeSingleTon.setDictValue(reservation.reference,values.evse);
              setChargingLoading(true);
              setDateStart(new Date().getTime());    
            } else {
              startErrorHandler();
            }
          });
        }}
      >
        <div className={styles.wrapper}>
          <ChargingPill
            //status={`in ${difference} minutes`}
            status={t(intlKeys.IN_MINUTES).replace('%1', String(difference))}
          />
          <EmptyList icon={<BlinkingBulb/>}>
            {t(intlKeys.LOOK_FOR_LED)}<br/>{t(intlKeys.SWIPE_CARD)}
          </EmptyList>
          <div>
            <p className={styles.message}>{t(intlKeys.DONT_HAVE_CARD)}</p>
            <div className={styles.select}>
              <SelectOption
                name="evse"
                formatter={formatEVSEId}
                options={evses}
                title={t(intlKeys.SELECT_CONNECTOR_NO)}
                defaultValue={`${reservation.chargerId}`}
                dropDown={true}
              />
              <Button
                type="submit"
                text={t(intlKeys.START_CHARGING)}
                outlined={false}
                className={cs(styles.startButton)}
              />
            </div>
          </div>
        </div>
      </Form>
      {chargingLoading && <Loading text={t(intlKeys.STARTING_SESSION)}/>}
    </Dialog>
  );
}

