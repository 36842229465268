import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../constants';
import { Location, Paginated } from '../types';

const locations = createApi({
  reducerPath: 'locationsApi',
  baseQuery,
  refetchOnReconnect: true,
  tagTypes: ['Location'],
  endpoints: ({ query }) => ({
    getLocations: query<Paginated<Location>, string>({
      query: (search) => ({
        url: 'locations',
        params: {
          page: 0,
          pageSize: 20,
          search
        }
      }),
      providesTags: (result) => result?.items.map(({ id }) => ({
        type: 'Location' as const,
        id
      })) ?? []
    }),
    getLocation: query<Location, {id: number, day: string, excludedReservationId:number}>({
      query: ({id, day, excludedReservationId}) => ({
        url: `locations/${id}`,
        params: {
          day,
          excludedReservationId
        }
      })
    })
  })
});

export const {
  useGetLocationsQuery,
  useGetLocationQuery
} = locations;

export default {
  path: locations.reducerPath,
  reducer: locations.reducer,
  middleware: locations.middleware
};
