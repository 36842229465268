import styles from './RadialProgress.module.scss';

type Props = {
  squareSize: number,
  percentage: number,
  strokeWidth: number
}

export function RadialProgress({squareSize, percentage, strokeWidth}: Props) {

  const viewBox = `0 0 ${squareSize} ${squareSize}`;
  const radius = (squareSize - strokeWidth);
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - dashArray * percentage / 100;

  return (
    <svg
      width={squareSize}
      height={squareSize}
      viewBox={viewBox}
      className={styles.radialProgress}
    >
      <circle
        className={styles.circleProgress}
        cx={squareSize / 2}
        cy={squareSize / 2}
        r={50}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-270 ${squareSize / 2} ${squareSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset
        }}
      />
    </svg>
  );
}