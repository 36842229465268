import { isSameMonth, isToday } from 'date-fns';
import { motion, MotionValue, useTransform } from 'framer-motion';

import { cs } from '../../utils/cs';

import styles from './CalendarDate.module.scss';

type Props = {
  date: Date,
  visible: Date,
  selected: boolean,
  reserved: boolean,
  progress: MotionValue<number>,
  setSelected: () => void
};

export function CalendarDate({ date, visible, selected, reserved, progress, setSelected }: Props) {
  const opacity = useTransform(progress, (value) => isSameMonth(date, visible) ? 1 : 1 - value);
  const pointerEvents = useTransform(opacity, (value) => value > .5 ? 'auto' : 'none');

  return (
    <motion.button
      className={cs(styles.date, {
        [styles.selected]: selected,
        [styles.reserved]: reserved,
        [styles.today]: isToday(date)
      })}
      style={{
        opacity,
        pointerEvents
      }}
      whileTap={{ scale: .9 }}
      onClick={setSelected}
    >
      {date.getDate()}
    </motion.button>
  );
}
