import nl from 'date-fns/locale/nl';
import { useTranslation } from 'react-i18next';

export function useDateLocale() {
  const { i18n } = useTranslation();

  return {
    nl
  }[i18n.language];
}
